import { resolveDirective } from "vue";
import axios from 'axios';
import lockerConfig from '../lockerConfig';

export default {
    async checkCode(code) {
        try {
            const result = await axios.post(`${lockerConfig.apiBase}/packages/checkCode`, {
                code: code
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            if (result.data.status != "success") {
                throw "Error response received.";
            }

            return result.data.message;
        } catch (error) {
            console.log('Invalid code or connection problem.', error);
            return false;
        }
    },

    async createDrawer(size, slaveId, solenoidId) {
        try {
            const result = await axios.post(`${lockerConfig.apiBase}/drawers/register`, {
                lockerId: lockerConfig.lockerId,
                slaveId: slaveId,
                solenoidId: solenoidId,
                sizeCode: size
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            if (result.data.status != "success") {
                throw "Error response received.";
            }
        } catch (error) {
            console.log('Drawer Create Error', error);
            return false;
        }

        return true;
    },

    async allDrawers() {
        let drawerData = [];

        try {
            const result = await axios.get(`${lockerConfig.apiBase}/drawers/getAll/${lockerConfig.lockerId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            if (result.data.status != "success") {
                throw "Error response received.";
            }

            drawerData = result.data.message;
        } catch (error) {
            console.log('All Drawers Get Error', error);
            return false;
        }

        return drawerData;
    },
    async updateDrawer(drawerData) {
        try {
            const result = await axios.post(`${lockerConfig.apiBase}/drawers/update`, {
                id: drawerData.id,
                lockerId: drawerData.locker_id,
                slaveId: drawerData.slave_id,
                solenoidId: drawerData.solenoid_id,
                sizeCode: drawerData.size_code,
                awaitingOperation: drawerData.awaiting_operation,
                status: drawerData.status,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            if (result.data.status != "success") {
                throw "Error response received.";
            }
        } catch (error) {
            console.log('All Drawers Get Error', error);
            return false;
        }

        return true;
    },
    async getDrawer(id) {
        try {
            const drawerData = await this.allDrawers();
            const found = drawerData.find(item => item.id == id);

            if (!found) {
                return false;
            }

            return found;
        } catch (error) {
            console.log('Drawer Get Error', error);
            return false;
        }
    },
    async freeDrawers() {
        let drawerData = [];

        try {
            drawerData = await this.allDrawers();
        } catch (error) {
            console.log('Free Drawers Get Error', error);
            throw false;
        }

        let sCount = 0;
        let mCount = 0;
        let lCount = 0;
        let xlCount = 0;
        drawerData.forEach((item, id) => {
            if (item.awaiting_operation != 0) return;
            switch (item.size_code) {
                case 's': sCount++; break;
                case 'm': mCount++; break;
                case 'l': lCount++; break;
                case 'xl': xlCount++; break;
            }
        });

        return {
            sCount: sCount,
            mCount: mCount,
            lCount: lCount,
            xlCount: xlCount
        };
    },
    async depositPackage(barcode, sizeCode, receiptEmail) {
        try {
            if (receiptEmail.trim() == "false") receiptEmail = false;
            const drawers = await this.allDrawers();
            let drawerId = -1;
            drawers.forEach(item => {
                if (item.awaiting_operation == 0 && item.size_code == sizeCode) {
                    drawerId = item.id;
                }
            });

            if (drawerId == -1) {
                console.log("No drawers found available with this size code.");
                return false;
            }

            const result = await axios.post(`${lockerConfig.apiBase}/packages/deposit`, {
                code: barcode,
                drawerId: drawerId,
                receiptEmail: receiptEmail
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            if (result.data.status != "success") {
                throw "Non-success response from API.";
            }

            console.log('Package Leave Post Success');
            return drawerId;
        } catch (error) {
            console.log('Package Leave Post Error', JSON.stringify(error));
            return false;
        }
    },
    async withdrawPackage(barcode) {
        try {
            const result = await axios.post(`${lockerConfig.apiBase}/packages/withdraw`, {
                code: barcode
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            if (result.data.status != "success") {
                throw "False received from API.";
            }

            if (result.data.message.lockerId != lockerConfig.lockerId) {
                throw "Package doesn't belong to this locker.";
            }

            console.log('Package Withdraw Post Success');
            return result.data.message.drawerId;
        } catch (error) {
            console.log('Package Withdraw Post Error', JSON.stringify(error));
            return false;
        }
    },
    async deleteDrawer(drawerId) {
        try {
            const result = await axios.post(`${lockerConfig.apiBase}/drawers/remove`, {
                lockerId: lockerConfig.lockerId,
                drawerId: drawerId
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            console.log('Drawer Remove Success');
            return true;
        } catch (error) {
            console.log('Drawer Removal Error', JSON.stringify(error));
            return false;
        }
    },
    async openDrawer(slaveId, solenoidId, tryCount = 0) {
        const errorSituation = async (errorMsg) => {
            if (tryCount >= 5) {
                console.log('Drawer opening system error', errorMsg);
                throw false;
            }

            return await this.openDrawer(slaveId, solenoidId, tryCount + 1);
        };

        try {
            const result = await axios.get(`http://192.168.1.${99 + slaveId}/open?solenoidId=${solenoidId}`);

            let data = result.data;
            if (data.status == 'success') {
                return true;
            } else {
                return await errorSituation(data.message);
            }
        } catch (error) {
            console.log("Error Open Drawer", error);
            return await errorSituation(error);
        }
    },
    async getSolenoidStatus(slaveId, solenoidId) {
        try {
            const result = await axios.get(`http://192.168.1.${99 + slaveId}/read?solenoidId=${solenoidId}`);

            let data = result.data;
            if (data.status == 'success') {
                return data.message;
            } else {
                console.log('Drawer status system error.', data.message);
                throw false;
            }
        } catch (error) {
            console.log('Drawer status error.', error);
            throw false;
        }
    },
    /*
    createBarcode() {
        return Math.floor(Math.pow(10, 12 - 1) + Math.random() * (Math.pow(10, 12) - Math.pow(10, 12 - 1) - 1));
    },
    async openDrawer(slaveId, solenoidId, tryCount = 0) {
        const errorSituation = async (errorMsg) => {
            if (tryCount >= 5) {
                console.log('Drawer opening system error', errorMsg);
                throw false;
            }

            return await this.openDrawer(slaveId, solenoidId, tryCount + 1);
        };

        try {
            const result = await axios.get(`http://192.168.1.100/open?slaveId=${slaveId}&outputId=${solenoidId}`);

            let data = result.data;
            if (data.type == 'success') {
                return true;
            } else {
                return await errorSituation(data.message);
            }
        } catch (error) {
            return await errorSituation(error);
        }
    },
    async getSolenoidStatus(slaveId, solenoidId) {
        try {
            const result = await axios.get(`http://192.168.1.100/read?slaveId=${slaveId}&outputId=${solenoidId}&typeId=1`);

            let data = result.data;
            if (data.type == 'success') {
                return data.data;
            } else {
                console.log('Drawer status system error.', data.message);
                throw false;
            }
        } catch (error) {
            console.log('Drawer status error.', error);
            throw false;
        }
    },
    async cleanUpAllDrawers() {
        const drawers = await this.allDrawers();
        for (let drawer of drawers) {
            await this.cleanUpDrawer(drawer.uniqueId);
        }

        return true;
    },
    async cleanUpDrawer(drawerId) {
        let drawerData = [];

        try {
            const result = await axios.get(`${lockerConfig.apiBase}/drawers/${lockerConfig.lockerId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            drawerData = result.data.data;
        } catch (error) {
            console.log('Drawer set status getter error', error);
            throw false;
        }

        let freeDrawer = -1;
        drawerData.forEach((item, id) => {
            if (item.uniqueId == drawerId) {
                freeDrawer = id;
            }
        });

        if (freeDrawer == -1) {
            console.log('Drawer not found for status update.');
            throw false;
        }

        drawerData[freeDrawer].status = lockerConfig.drawerStatus.AVAILABLE;
        drawerData[freeDrawer].recipientName = "";
        drawerData[freeDrawer].recipientEmail = "";
        drawerData[freeDrawer].recipientPhone = "";
        drawerData[freeDrawer].rfidLeaver = 0;
        drawerData[freeDrawer].rfidRecipient = 0;
        drawerData[freeDrawer].barcodeNumber = 0;
        drawerData[freeDrawer].reservedBy = 0;

        try {
            const result = await axios.post(`${lockerConfig.apiBase}/drawers/setData`, {
                lockerId: lockerConfig.lockerId,
                drawerData: drawerData
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            console.log('Drawer Status Set Success', drawerData[freeDrawer].uniqueId);
            return true;
        } catch (error) {
            console.log('Drawer Status Set Error', JSON.stringify(error.response.data));
            throw false;
        }
    },
    async deleteDrawer(drawerId) {
        let drawerData = [];

        try {
            const result = await axios.get(`${lockerConfig.apiBase}/drawers/${lockerConfig.lockerId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            drawerData = result.data.data;
        } catch (error) {
            console.log('Drawer set status getter error', error);
            throw false;
        }

        let freeDrawer = -1;
        drawerData.forEach((item, id) => {
            if (item.uniqueId == drawerId) {
                freeDrawer = id;
            }
        });

        if (freeDrawer == -1) {
            console.log('Drawer not found for deletion.');
            throw false;
        }

        drawerData.splice(freeDrawer, 1);

        try {
            const result = await axios.post(`${lockerConfig.apiBase}/drawers/setData`, {
                lockerId: lockerConfig.lockerId,
                drawerData: drawerData
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            console.log('Drawer Status Set Success');
            return true;
        } catch (error) {
            console.log('Drawer Status Set Error', JSON.stringify(error.response.data));
            throw false;
        }
    },

    async setDrawerStatus(drawerId, statusCode, tryCount) {
        let drawerData = [];

        try {
            const result = await axios.get(`${lockerConfig.apiBase}/drawers/${lockerConfig.lockerId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            drawerData = result.data.data;
        } catch (error) {
            console.log('Drawer set status getter error', error);
            throw false;
        }

        let freeDrawer = -1;
        drawerData.forEach((item, id) => {
            if (item.uniqueId == drawerId) {
                freeDrawer = id;
            }
        });

        if (freeDrawer == -1) {
            console.log('Drawer not found for status update.');
            throw false;
        }

        drawerData[freeDrawer].status = statusCode;

        try {
            const result = await axios.post(`${lockerConfig.apiBase}/drawers/setData`, {
                lockerId: lockerConfig.lockerId,
                drawerData: drawerData
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            console.log('Drawer Status Set Success', drawerData[freeDrawer].uniqueId);
            return true;
        } catch (error) {
            console.log('Drawer Status Set Error', JSON.stringify(error.response.data));
            throw false;
        }
    },
    async leavePackage(drawerId) {
        try {
            const result = await axios.post(`${lockerConfig.apiBase}/package/leave`, {
                lockerId: lockerConfig.lockerId,
                drawerId: drawerId
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            console.log('Package Leave Post Success');
            return true;
        } catch (error) {
            console.log('Package Leave Post Error', JSON.stringify(error.response.data));
            throw false;
        }
    },
    async receivePackage(drawerId) {
        try {
            const result = await axios.post(`${lockerConfig.apiBase}/package/receive`, {
                lockerId: lockerConfig.lockerId,
                drawerId: drawerId
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            console.log('Package Receive Post Success');
            return true;
        } catch (error) {
            console.log('Package Receive Post Error', JSON.stringify(error.response.data));
            throw false;
        }
    },
    async reservation(sizeCode, name, email, rpName, rpEmail, rpPhone) {
        let drawerData = [];
        let result;

        try {
            result = await axios.post(`${lockerConfig.apiBase}/reserve/barcode`, {
                lockerId: lockerConfig.lockerId,
                sizeCode: sizeCode,
                reserverName: name.split(" ")[0],
                reserverSurname: name.split(" ")[1],
                recipientName: rpName,
                recipientEmail: rpEmail,
                recipientPhone: rpPhone,
                reserverEmail: email
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });
        } catch (error) {
            console.log('Reservation Post Error', error);
            throw false;
        }

        return result.data.data || false;
    },
    async getRFIDUser(rfidUUID) {
        let users = [];

        try {
            const result = await axios.get(`${lockerConfig.apiBase}/rfid/get/${lockerConfig.lockerId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            users = result.data.data;
        } catch (error) {
            console.log('RFID Users Get Error', error);
            return false;
        }

        if (users.length <= 0) {
            return false;
        }

        let found = -1;

        users.forEach((item, index) => {
            if (item.rfid_uuid == rfidUUID) {
                found = index;
            }
        });

        if (found < 0) {
            return false;
        }

        return users[found];
    },
    async checkRFIDUsername(username) {
        let users = [];

        try {
            const result = await axios.get(`${lockerConfig.apiBase}/rfid/get/${lockerConfig.lockerId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            users = result.data.data;
        } catch (error) {
            console.log('RFID Users Get Error', error);
            return false;
        }

        if (users.length <= 0) {
            return false;
        }

        let found = -1;

        users.forEach((item, index) => {
            if (item.username == username) {
                found = index;
            }
        });

        if (found < 0) {
            return false;
        }

        return users[found];
    },
    async createRFIDUser(name, surname, username, rfidUUID) {
        let result = {};
        try {
            result = await axios.post(`${lockerConfig.apiBase}/rfid/create`, {
                lockerId: lockerConfig.lockerId,
                name: name,
                surname: surname,
                username: username,
                rfidUUID: rfidUUID
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });
        } catch (error) {
            console.log('RFID User Create Error', error, result);
            if (typeof error.response.data != 'undefined') {
                throw error.response.data;
            } else {
                throw {
                    message: 'Internal system error occured, please try again later.'
                }
            }
        }

        return true;
    },
    async rfidReservation(sizeCode, rfidId, recipientRFIDId) {
        try {
            const result = await axios.post(`${lockerConfig.apiBase}/reserve/rfid`, {
                lockerId: lockerConfig.lockerId,
                sizeCode: sizeCode,
                leaverRFIDId: rfidId,
                recipientRFIDId: recipientRFIDId
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.apiToken}`
                }
            });

            console.log('RFID Reservation Success');
        } catch (error) {
            console.log('RFID Reservation Post Error', JSON.stringify(error.response.data));
            throw false;
        }

        return true;
    }
    */
};